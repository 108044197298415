/* Basic styling */

:root{
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background:rgba(246, 179, 51, 1);
  --secondary:rgba(241,194,50, 0.8);

}

*,
*::before,
*::after{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body{
  background-color: var(--background);
  font-family: 'Poppins', sans-serif;
}